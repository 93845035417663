import React, { useState, useEffect, useContext } from "react";
import { BrowserRouter as Router, Route, Routes, useLocation  } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
//  * Traduccion 
import { LanguageContext }  from "./utils/LanguageProvider";
import { fetchConsolidadoCenters } from "./utils/apiServer";

import Login from "./pages/Login";
import Registro from "./pages/Registro";
import CreateCenters from "./pages/CreateCenters";

import Home from "./pages/Home";
import Usuarios from "./pages/Usuarios";
// * Rutas Enviro
import MainEnviro from './services/Enviro/MainEnviro';
// * Rutas Nanoox
import MainNanoox from "./services/Nanoox/MainNanoox";
//* Rutas PSS
import MainPSS from "./services/PSS/MainPSS";
import SLA from "./services/PSS/pages/SLA";

import TitleUpdater from "./utils/TitleUpDater";
// import { ExpiredSession } from "./utils/ApiLogin";



const SWVersion = 'v20240917'

const darkTheme = createTheme({
    palette: {
        mode: 'dark',
        background: {
            default: '#000', // Cambia este color al que desees
        },
    },
});


function App() {
  
  // const [consolidado_estados, setConsolidado_estados] = useState({});
  const [selectedRegion, setSelectedRegion] = useState(null);
  const [selectedArea, setSelectedArea] = useState(null);
  const [fileContent, setFileContent] = useState('');
  const [registro, setRegistro] = useState(false);
  const { translate, changeLanguage } = useContext(LanguageContext); // Usa el hook useContext para acceder al contexto
  const [openMenuIdioma, setOpenMenuIdioma] = useState(null); // Estado para controlar la apertura del menú de los posibles lenguajes
  const [idioma, setIdioma] = useState(localStorage.getItem('selectedLanguage'));
  const [cargo, setCargo] = useState(localStorage.getItem('cargo'));


  const handleLanguageChange = (newLanguage) => {
    changeLanguage(newLanguage);
    // Guarda el idioma seleccionado en el Local Storage
    setIdioma(newLanguage)
    localStorage.setItem('selectedLanguage', newLanguage);
    handleCloseIdioma(); // Cierra el menú después de seleccionar un idioma
  };

  const handleClick = (event) => {
    setOpenMenuIdioma(event.currentTarget); // Abre el menú cuando se hace clic en el botón
  };

  const handleCloseIdioma = () => {
    setOpenMenuIdioma(null); // Cierra el menú
  };


  useEffect(() => {
    fetch('test.txt')
      .then(response => response.text())
      .then(text => setFileContent(text))
      .catch(error => console.error('Error al obtener el archivo:', error));
    
    
    if (fileContent != ''){
      console.log("contenido del archivo", fileContent)
      localStorage.setItem("serverName", fileContent)
    }
    console.log("contenido del archivo", fileContent)

  }, [fileContent]);


//   useEffect(() => {
//     const fetchData = async () => {
//         const result = await fetchConsolidadoCenters();
//         setConsolidado_estados(result);
//         // //console.log('response: consolidado_estados', result)

//     };

//     // Fetch data initially
//     fetchData();

//     // Set interval to fetch data every 60 seconds
//     const interval = setInterval(fetchData, 60000);

//     // Clean up interval on component unmount
//     return () => clearInterval(interval);
// }, []);

  // useEffect(() => {
  //   const checkToken = async () => { 
  //     const result = await ExpiredSession(fileContent, setSessionExpired)
  //     console.log('result', result)
  //   }
    
  //   checkToken()
    
  //     const interval = setInterval(checkToken, 1 * 60 * 1000); // Check every 5 minutes
  //   return () => clearInterval(interval);
  // }, []);
  

  const LoginProps = {
    SWVersion: SWVersion,
    fileContent: fileContent,
    translate: translate,
    handleClick: handleClick,
    openMenuIdioma:openMenuIdioma,
    handleCloseIdioma:handleCloseIdioma,
    handleLanguageChange:handleLanguageChange,
  } 

  const RegistroProps = {
    translate: translate,
    handleClick: handleClick,
    openMenuIdioma:openMenuIdioma,
    handleCloseIdioma:handleCloseIdioma,
    handleLanguageChange:handleLanguageChange,
    cargo:cargo
  } 

  const CreateCentersProps = {
    translate: translate,
    handleClick: handleClick,
    openMenuIdioma:openMenuIdioma,
    handleCloseIdioma:handleCloseIdioma,
    handleLanguageChange:handleLanguageChange,
    idioma:idioma
  } 

  const HomeProps = {
    SWVersion:SWVersion,
    registro: registro,
    translate: translate,
    handleClick: handleClick,
    openMenuIdioma:openMenuIdioma,
    handleCloseIdioma:handleCloseIdioma,
    handleLanguageChange:handleLanguageChange,
    cargo:cargo,
    selectedRegion:selectedRegion,
    setSelectedRegion:setSelectedRegion,
    selectedArea:selectedArea,
    setSelectedArea:setSelectedArea,
    // consolidado_estados:transformData(consolidado_estados)
  } 

  const NanooxProps = {
    translate: translate,
    handleClick: handleClick,
    openMenuIdioma:openMenuIdioma,
    handleCloseIdioma:handleCloseIdioma,
    handleLanguageChange:handleLanguageChange,
    idioma:idioma,
    selectedRegion:selectedRegion,
    selectedArea:selectedArea,
    setSelectedRegion:setSelectedRegion,
    setSelectedArea:setSelectedArea,
    // consolidado_estados:transformData(consolidado_estados)
  } 

  const EnviroProps = {
    translate: translate,
    handleClick: handleClick,
    openMenuIdioma:openMenuIdioma,
    handleCloseIdioma:handleCloseIdioma,
    handleLanguageChange:handleLanguageChange,
    idioma:idioma,
    selectedRegion:selectedRegion,
    selectedArea:selectedArea,
    setSelectedRegion:setSelectedRegion,
    setSelectedArea:setSelectedArea,
    // consolidado_estados:transformData(consolidado_estados)
  } 

  const PSSProps = {
    translate: translate,
    handleClick: handleClick,
    openMenuIdioma:openMenuIdioma,
    handleCloseIdioma:handleCloseIdioma,
    handleLanguageChange:handleLanguageChange,
    idioma:idioma,
    selectedRegion:selectedRegion,
    selectedArea:selectedArea,
    setSelectedRegion:setSelectedRegion,
    setSelectedArea:setSelectedArea,
    // consolidado_estados:transformData(consolidado_estados)
  } 

  return (
    <Router style={{ cursor: 'default !important', userSelect: 'none', }}>
      <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      {/* <TitleUpdater consolidadoEstados={consolidado_estados?.regiones} /> */}
        <Routes>
          <Route path="/" element={<Login {...LoginProps} />}/>
          <Route path="/home" element={<Home {...HomeProps}/>}/>
          <Route path="/Registro" element={<Registro {...RegistroProps}/>}/>
          <Route path="/Usuarios" element={<Usuarios/>}/>
          <Route path="/CreateCenters" element={<CreateCenters {...CreateCentersProps}/>}/>

          {/* //* Ruta Enviro */}

          <Route path="/Enviro/:region?/:area?/:centro?" element={<MainEnviro {...EnviroProps}/>}/>
          {/* //*Ruta Nanoox */}
          
          <Route path='/Nanoox/:region?/:area?/:centro?' element={<MainNanoox {...NanooxProps}/>}/>

          {/* //*Ruta PSS */}
          
          <Route path='/PSS/:region?/:area?/:centro?' element={<MainPSS {...PSSProps}/>}/>
          <Route path='/PSS Servicio SLA/:region?/:area?/:centro?' element={<SLA {...PSSProps}/>}/>

        </Routes>
    </ThemeProvider>
    </Router>
  );
}

export default App;
